import { _axios } from '@/utils/axios'

export function newOrder(data) { // 生成订单
  return _axios({
    url: '/accelerator/order',
    method: 'post',
    data
  })
}
 
export function purchase(data) { // 下单
  return _axios({
    url: `/accelerator/order`,
    method: 'put',
    data
  })
}
