<template>
  <div class="shangche">
    <div class="car-form">
      <div class="product">
        <img src="../../../assets/switch.png" />
        <div class="sku">
          <div class="name">
            商品：{{
              plan.stName.indexOf('下载流量') !== -1
                ? 'eshop下载流量套餐'
                : 'eshop下载加量包'
            }}
          </div>
          <div class="duration">流量：{{ plan.name }}</div>
          <div class="duration">加速类型：游戏下载加速</div>
        </div>
      </div>
      <div class="input-item">
        <div class="title-star">
          {{ plan.stName.indexOf('下载流量') !== -1 ? '邮箱' : '用户名' }}
        </div>
        <input
          v-model="email"
          :placeholder="
            plan.stName.indexOf('下载流量') !== -1
              ? '用于接收给您发送的配置信息'
              : '代理服务器配置信息的用户名'
          "
        />
      </div>
      <div class="input-item" v-if="!isWeixin">
        <div class="title-star">联系方式</div>
        <input v-model="contact" placeholder="用于查询订单，建议使用手机号" />
      </div>
      <Payment
        @methodTab="paymentMethodTab"
        @getOrderInfo="getOrderInfo"
        :method="payMethod"
        :showBrowserGuide="showBrowserGuide"
      ></Payment>
    </div>
    <div class="blank-area"></div>
    <div class="footer">
      <div class="pay">
        <div class="pay-amount">
          <span class="label">支付金额：</span>
          <span class="currency">￥</span>
          <span class="amount">{{ plan.price }}</span>
        </div>
        <button
          class="pay-button"
          :class="{ traffic: plan.type !== 1 }"
          @click="submitOrder"
        >
          支付
        </button>
      </div>
    </div>
    <!-- <div class="cs" @click="qrVisible = true">
      <div class="cs-wrap">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="0.4rem"
          height="0.4rem"
          viewBox="0 0 20 20"
          version="1.1"
        >
          <path
            d="M18.7500049,7.37493323 C18.0000547,3.1249823 14.3750317,0 10.0000781,0 C5.62494873,0 2.0000625,3.25000269 1.24997559,7.3749137 C0.499986328,7.99995703 0,9.12500378 0,10.2499724 C0,11.7499631 0.874990723,13.4999556 2.25000684,13.4999556 C3.49996289,13.4999556 3.49996289,12.1249852 3.49996289,10.2499724 C3.49996289,8.49997998 3.49996289,7.12497058 2.62501123,6.99995019 C3.37494189,3.62498572 6.37503564,1.12496863 10.0000781,1.12496863 C13.6249644,1.12496863 16.6250386,3.62498572 17.3750083,6.87502747 C16.5000176,7.12499011 16.5000176,8.37495959 16.5000176,10.1249325 C16.5000176,11.3636519 16.5000176,12.2340485 16.743204,12.8578223 C16.7454306,12.863545 16.7512119,12.8689356 16.7499619,12.8749318 C16.041731,16.2304625 14.0000469,17.6249642 11.9999844,18.1250067 C11.625019,17.6249642 10.8750298,17.2500203 10.0000586,17.2500203 C8.74992676,17.2500203 7.74999316,17.8750245 7.74999316,18.6249906 C7.74999316,19.3750739 8.74992676,20 10.0000586,20 C11.2500146,20 12.2500264,19.3750739 12.2500264,18.6249906 C14.2500107,18.1250067 16.6667383,16.399741 17.1250444,13.2498953 C17.1242632,13.2552664 17.1328375,13.2574734 17.1369196,13.2610672 C17.265339,13.3749352 17.5077052,13.3749352 17.7499932,13.3749352 C19.1249897,13.3749352 20,11.6249232 20,10.124913 C20,9.12500378 19.5,7.99995703 18.7500049,7.37493323 Z"
            id="路径"
          />
        </svg>
        <div style="font-size: 0.24rem">客服</div>
      </div>
    </div> -->
    <Toast
      :showToast="showToast"
      :message="toastMsg"
      @disappear="showToast = false"
    ></Toast>
    <Loading :display="showLoading"></Loading>
    <confirm
      :visible="confirmVisible"
      @cancel="confirmCancel"
      :confirmText="confirmText"
      :cancelText="cancelText"
      @confirm="confirm"
    >
      <div v-if="confirmType == 1" class="confirm-body">
        请确认支付是否已完成？
      </div>
      <div class="confirm-body" v-else>
        <div class="dialog-body-row">
          <div>确认要放弃付款？</div>
        </div>
        <div class="dialog-body-row">
          <div class="rights">
            <div class="right">
              <img src="../../../assets/rocket.svg" />
              <div>极速发车</div>
            </div>
            <div class="right">
              <img src="../../../assets/shield.svg" />
              <div>信誉保障</div>
            </div>
          </div>
        </div>
      </div>
    </confirm>
    <WechatQR :visible="qrVisible" @cancel="qrVisible = false"></WechatQR>
  </div>
</template>
<script>
/* eslint-disable */
import { purchase } from '@/api/accelerator/order'
import { getOrderInfo } from '@/api/order'
import Toast from '@/components/Toast'
import Loading from '@/components/Loading'
import Confirm from '@/components/Confirm'
import Zhimafen from '@/components/Zhimafen'
import WechatQR from '@/components/WechatQR'
import debounce from '@/utils/debounce'
import { reportError } from '@/utils/log'
import Payment from '@/components/Payment'

export default {
  name: 'Home',
  data() {
    return {
      payMethod: 'wechatPay',
      showBrowserGuide: false,
      cancelText: '稍后再付',
      confirmText: '继续支付',
      showLoading: false,
      confirmVisible: false,
      qrVisible: false,
      confirmType: 2,
      toastMsg: '',
      contact: '',
      email: '',
      showToast: false,
      plan: {
        id: 0,
        name: '',
        price: 0,
        stName: ''
      },
      orderId: '',
      isWeixin: false,
      isPC: true,
      jssdkReady: false,
      isIOS: false,
      order: {
        status: 1
      }
    }
  },
  components: {
    Toast,
    Loading,
    Zhimafen,
    Confirm,
    Payment,
    WechatQR
  },
  beforeRouteLeave(to, from, next) {
    if (this.order.status != 3) {
      this.cancelText = '稍后再付'
      this.confirmText = '继续支付'
      this.showConfirm(2)
        .then(res => {
          this.confirmVisible = false
          next(false)
        })
        .catch(e => {
          next()
        })
    }
  },
  methods: {
    paymentMethodTab(method) {
      this.payMethod = method
    },
    showConfirm(confirmType) {
      this.confirmType = confirmType
      this.confirmVisible = true
      return new Promise((resolve, reject) => {
        this.confirm = () => {
          resolve('success')
        }
        this.confirmCancel = () => {
          reject('fail')
        }
      })
    },
    confirm() {},
    confirmCancel() {},
    async getOrderInfo(flag = false) {
      try {
        this.showBrowserGuide = false
        this.showLoading = true
        let res = await getOrderInfo({
          orderId: this.orderId
        })
        this.showLoading = false
        if (res.data.code == 0) {
          this.order = res.data.order
          this.plan.stName = res.data.order.stName
          this.plan.name = res.data.order.skuName
          this.plan.price = res.data.order.price
          this.email = res.data.order.email || ''
          this.contact = res.data.order.meta.contact || ''

          if (res.data.order.status == 2) {
            // 未付款
            if (!this.email) {
              // 如果是来重新付款的，需要获取订单的信息填到页面上
              this.plan.type = Number(res.data.order.type)
            }
            if (flag) {
              this.toastMsg = '您的订单还未完成支付，请重新支付'
              this.showToast = true
            }
          } else if (res.data.order.status == 3) {
            // 付款成功
            window.location =
              '/accelerator/order/pay-success?orderId=' + this.orderId
          }
        }
      } catch (error) {
        reportError(error)
      }
    },
    checkMail(email) {
      return /^[0-9a-zA-Z_.+-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(
        email
      )
    },
    submitOrder: debounce(async function () {
      try {
        if (this.plan.stName == '下载流量') {
          if (!this.email) {
            this.toastMsg = '邮箱不能为空'
            this.showToast = true
            return
          }
          this.email = this.email.replace(/\s+/g, '')
          if (!this.checkMail(this.email)) {
            this.toastMsg = '请输入正确的邮箱'
            this.showToast = true
            return
          }
        } else {
          // 流量包
          this.email = this.email.replace(/\s+/g, '')
          if (!this.email) {
            this.toastMsg = '用户名不能为空'
            this.showToast = true
            return
          }
        }

        if (!this.isWeixin) {
          if (!this.contact) {
            this.toastMsg = '联系方式不能为空'
            this.showToast = true
            return
          }
          if (!/^1[3456789]\d{9}$/.test(this.contact)) {
            this.toastMsg = '手机号填写错误'
            this.showToast = true
            return
          }
        }
        this.showLoading = true
        let purchaseParams = {
          email: this.email,
          contact: this.contact,
          orderId: this.orderId,
          isWeixin: this.isWeixin,
          payMethod: this.payMethod
        }
        if (this.isPC) purchaseParams['qr'] = true
        let res = await purchase(purchaseParams)
        this.showLoading = false
        if (res.data.code == 0) {
          if (this.payMethod == 'alipay') {
            if (this.isWeixin) {
              // 在微信环境中打开，指引去浏览器打开
              const newUrl =
                this.$route.path + `?orderid=${this.orderId}&payMethod=alipay`
              window.history.replaceState('', '', newUrl)
              this.showBrowserGuide = true
            } else {
              const div = document.createElement('formdiv')
              div.innerHTML = res.data.data
              document.body.appendChild(div)
              document.forms[0].setAttribute(
                'target',
                this.isPC ? '_blank' : '_self'
              )
              document.forms[0].submit()
              div.remove()
              setTimeout(() => {
                this.cancelText = '未完成'
                this.confirmText = '完成'
                this.showConfirm(1)
                  .then(res => {
                    this.confirmVisible = false
                    this.getOrderInfo(true)
                  })
                  .catch(e => {
                    this.confirmVisible = false
                  })
              }, 5000)
            }
          } else {
            if (this.isWeixin) {
              if (typeof WeixinJSBridge == 'undefined') {
                if (document.addEventListener) {
                  document.addEventListener(
                    'WeixinJSBridgeReady',
                    this.jsPay(res.data.data),
                    false
                  )
                } else if (document.attachEvent) {
                  document.attachEvent(
                    'WeixinJSBridgeReady',
                    this.jsPay(res.data.data)
                  )
                  document.attachEvent(
                    'onWeixinJSBridgeReady',
                    this.jsPay(res.data.data)
                  )
                }
              } else {
                this.jsPay(res.data.data)
              }
            } else if (this.isPC) {
              let routeUrl = this.$router.resolve({
                path: '/accelerator/order/pay-pc',
                query: {
                  orderId: this.orderId,
                  qrUrl: res.data.data.codeUrl
                }
              })
              setTimeout(() => {
                this.showConfirm(1)
                  .then(res => {
                    this.confirmVisible = false
                    this.getOrderInfo(true)
                  })
                  .catch(e => {
                    this.confirmVisible = false
                  })
              }, 1500)
              window.open(routeUrl.href, '_blank')
            } else {
              window.location.href = res.data.data.url
              setTimeout(() => {
                this.cancelText = '未完成'
                this.confirmText = '完成'
                this.showConfirm(1)
                  .then(res => {
                    this.confirmVisible = false
                    this.getOrderInfo(true)
                  })
                  .catch(e => {
                    this.confirmVisible = false
                  })
              }, 1500)
            }
          }
        } else if (res.data.code == 2) {
          // 订单已支付
          this.$router.push(
            '/accelerator/order/pay-success?orderId=' + this.orderId
          )
        } else {
          this.toastMsg = res.data.message
          this.showToast = true
        }
      } catch (error) {
        console.log(error)
        reportError(error)
      }
    }, 200),
    jsPay(data) {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: 'wx1d62934604321a9c', //公众号名称，由商户传入
          timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: data.nonceStr, //随机串
          package: data.package,
          signType: data.signType, //微信签名方式
          paySign: data.paySign //微信签名
        },
        res => {
          this.getOrderInfo()
          // alert(res.err_msg)
          // if (res.err_msg == "get_brand_wcpay_request:ok" ){
          //   // 使用以上方式判断前端返回,微信团队郑重提示：
          //   //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          //   this.getOrderInfo(true)
          // } else {
          //   this.toastMsg = '您的订单还未完成支付，请重新支付'
          //   this.showToast = true
          // }
        }
      )
    }
  },
  async created() {
    try {
      let ua = navigator.userAgent
      this.isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //iOS
      ua = ua.toLowerCase()
      this.isWeixin = ua.indexOf('micromessenger') !== -1
      let phoneAgents = [
        'android',
        'iphone',
        'symbianos',
        'windows phone',
        'ipad',
        'ipod'
      ]
      for (let agent of phoneAgents) {
        if (ua.indexOf(agent) !== -1) {
          this.isPC = false
          break
        }
      }
      if (this.isPC) this.isWeixin = false // 如果是PC微信端浏览器打开，就当作PC打开
      if (this.$route.query.orderid) {
        this.orderId = this.$route.query.orderid
        if (this.$route.query.payMethod == 'alipay') {
          this.payMethod = 'alipay'
          if (!this.isWeixin) {
            let purchaseParams = {
              orderId: this.orderId,
              isWeixin: false,
              payMethod: 'alipay'
            }
            this.showLoading = true
            let res = await purchase(purchaseParams)
            this.showLoading = false
            if (res.data.code == 0) {
              const div = document.createElement('formdiv')
              div.innerHTML = res.data.data
              document.body.appendChild(div)
              document.forms[0].setAttribute(
                'target',
                this.isPC ? '_blank' : '_self'
              )
              document.forms[0].submit()
              div.remove()
              setTimeout(() => {
                this.cancelText = '未完成'
                this.confirmText = '完成'
                this.showConfirm(1)
                  .then(res => {
                    this.confirmVisible = false
                    this.getOrderInfo(true)
                  })
                  .catch(e => {
                    this.confirmVisible = false
                  })
              }, 5000)
            }
          }
        }
        await this.getOrderInfo()
      } else {
        this.$router.push('/home')
      }
    } catch (error) {
      reportError(error)
    }
  }
}
</script>

<style lang="less" scoped>
.shangche {
  width: 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100% !important;
  margin-bottom: 1.1rem;
  background: #f8f8f8;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .car-form {
    width: 7.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .product {
      margin-bottom: 0.12rem;
      background: #ffffff;
      width: 7.02rem;
      padding: 0.12rem 0.24rem;
      height: 1.8rem;
      display: flex;
      align-items: center;
      img {
        width: 1.4rem;
        height: 1.4rem;
      }
      .sku {
        margin-left: 0.18rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 0.28rem;
        height: 1.4rem;
      }
    }

    .input-item {
      height: 0.9rem;
      width: 7.02rem;
      padding: 0.06rem 0.24rem;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 0.28rem;
      }
      .title-star {
        font-size: 0.28rem;
        &::after {
          content: '*';
          color: red;
        }
      }
      input {
        width: 65%;
        border: none;
        height: 0.4rem;
        padding: 0.24rem 0;
        font-size: 0.28rem;
        outline: none;
        text-align: right;
        &::placeholder {
          font-size: 0.28rem;
          color: #c5c6c5;
        }
        &:focus {
          border: none;
        }
        &:active {
          border: none;
        }
        &:-moz-placeholder {
          text-align: right;
        }
        &::-moz-placeholder {
          text-align: right;
        }
        &::-webkit-input-placeholder {
          text-align: right;
        }
        &:-ms-input-placeholder {
          text-align: right;
        }
      }
      border-bottom: 0.02rem solid #f6f7f8;
      &:last-child {
        border-bottom: 0;
      }
      .screenshot-demo {
        font-size: 0.28rem;
        color: #54cea8;
        cursor: pointer;
      }
    }
  }
  .footer {
    background: #ffffff;
    height: 1.1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 7.5rem;
    .pay {
      border-top: 0.01rem solid #f6f7f8;
      width: 7.5rem;
      height: 1.1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .pay-amount {
        margin-left: 0.24rem;
        font-size: 0.28rem;
        .label {
          color: #050608;
        }
        .currency {
          font-size: 0.2rem;
          color: #ff4a4a;
        }
        .amount {
          font-size: 0.4rem;
          color: #ff4a4a;
        }
      }
      .pay-button {
        margin-right: 0.24rem;
        width: 2.2rem;
        border: none;
        border-radius: 0.4rem;
        font-size: 0.32rem;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
        background: linear-gradient(90deg, #53ccaa 0%, #5bd88f 100%);
        height: 0.8rem;
        &.ex {
          background: linear-gradient(90deg, #e67649 0%, #e5884b 100%);
        }
        &:disabled {
          background: #f6f7f8;
          color: #999999;
        }
        ::after {
          border: none;
        }
      }
    }
  }
  .confirm-body {
    height: 2.2rem;
    width: 100%;
    font-size: 0.32rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    color: #939393;
    .dialog-body-row {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .rights {
        width: 3.8rem;
        height: 0.52rem;
        display: flex;
        justify-content: space-between;
        .right {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.56rem;
          height: 0.52rem;
          font-size: 0.24rem;
          background: #f8f8f8;
          img {
            width: 0.28rem;
            height: 0.28rem;
            margin-right: 0.04rem;
          }
        }
      }
    }
  }
  .cs {
    position: fixed;
    bottom: 3rem;
    right: 0.24rem;
    cursor: pointer;
    .cs-wrap {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      box-shadow: 0px 0px 0.04rem 0.04rem rgba(0, 0, 0, 0.07);
    }
  }
}
</style>
